/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import NoData from "@/components/common/NoData";
Vue.component(NoData.name, NoData);
export default {
  name: "myCustomList",
  data() {
    return {
      list: []
    };
  },
  created() {
    var url = _baseUrl + "/mobile/custom/getMyMobileList.do";
    this.$ajax.get(url).then(res => {
      this.list = res.data;
    });
  }
};